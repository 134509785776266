import { goto } from '$app/navigation';
import {
  NotificationExpiration,
  notifications,
  NotificationType
} from '$lib/store/notification.store';
import { page } from '$app/stores';
import { get } from 'svelte/store';
import { dev } from '$app/environment';
import * as Sentry from '@sentry/svelte';
// 	import type { Provider } from '@supabase/gotrue-js/dist/module/lib/types';
import type { Provider } from '@supabase/supabase-js';
import { LOGIN_SOCIAL, SIGN_UP_SOCIAL } from '$lib/constants';

type OptionsType = {
  redirectTo?: string | undefined;
  scopes?: string | undefined;
  queryParams?: {
    [key: string]: string;
  } | undefined;
  skipBrowserRedirect?: boolean | undefined;
}

/**
 * This function signs up a user using email and password.
 * Throws an error when things went wrong.
 * @param email The user email address.
 * @param password The user password.
 * @param terms_accepted Whether the user accepted the terms of service.
 * @param receive_ebook Whether the user wants to receive the ebook.
 * @param newsletter_accepted Whether the user wants to receive the newsletter.
 * @param before_signup_path The path the user was on before signing up.
 * @returns Data if the sign up succeeded - an error otherwise.
 */
export async function signUpWithEmail(
  email: string,
  password: string,
  username: string,
  receive_ebook: boolean,
  newsletter_accepted: boolean,
  before_signup_path: string
) {
  try {
    let { data, error } = await get(page).data.supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: `https://${get(page).url.host}/auth/callback`,
        data: {
          username: username,
          receive_ebook: receive_ebook,
          newsletter_accepted: newsletter_accepted,
          before_signup_path: before_signup_path
        }
      }
    });
    if (error) {
      throw error;
    } else {
      notifications.createTolgeeNotification({
        type: NotificationType.Success,
        message: 'notification_signup_successful'
      });

      return data;
    }
  } catch (error) {
    notifications.createTolgeeNotification({
      type: NotificationType.Error,
      message: 'notification_signup_unsuccessful'
    });
    console.error('error while signing up:', error);

    throw error;
  }
}

/**
 * Logs the user in with email and password.
 * Throws an error when things went wrong.
 * @param email
 * @param password
 * @returns
 */
export async function logInWithEmail(email: string, password: string) {
  try {
    let { data, error } = await get(page).data.supabase.auth.signInWithPassword({
      email,
      password,
      options: {
        // emailRedirectTo: `https://${get(page).url.host}/auth/callback`,
      }
    });
    if (error) {
      throw error;
    } else {
      notifications.createTolgeeNotification({
        type: NotificationType.Success,
        message: 'notification_login_successful'
      });
      return data;
    }
  } catch (error) {
    notifications.createTolgeeNotification({
      type: NotificationType.Error,
      message: 'notification_login_unsuccessful'
    });
    console.error('error while logging in:', error);
    throw error;
  }
}

/**
 * Logs the user in via the provided third-party provider.
 * @param provider The third-party provider that is used for the login.
 * @param options Optional: Use to provide further options like a
*  "redirectTo: string" URL etc,
 * @returns Data if the login succeeded - an error otherwise.
 */
export async function logInWithSocial(provider: Provider, options?: OptionsType, isSignUp?: Boolean) {
  try {
    if (isSignUp) {
      localStorage.setItem(SIGN_UP_SOCIAL, "true");
    } else {
      localStorage.setItem(LOGIN_SOCIAL, "true");
    }
    const { data, error } = await get(page).data.supabase.auth.signInWithOAuth({ provider: provider, options: options });

    if (error) {
      throw error;
    } else {
      // TODO: Check, why the notification is shown too early when social sign in
      // if (!isSignUp) {
      // 	notifications.createTolgeeNotification({
      // 		type: NotificationType.Success,
      // 		message: 'notification_login_successful'
      // 	});
      // }
      console.log(data);
      return data;
    }
  } catch (error) {
    notifications.createTolgeeNotification({
      type: NotificationType.Error,
      message: 'notification_login_unsuccessful'
    });
    console.error('error while logging in:', error);
    throw error;
  }
}

export async function logOut(redirect?: string) {
  try {
    let { error } = await get(page).data.supabase.auth.signOut();
    if (error) throw error;
    if (redirect) goto(redirect);
    notifications.createTolgeeNotification({
      type: NotificationType.Success,
      message: 'notification_logout_successful'
    });
  } catch (error) {
    notifications.createTolgeeNotification({
      type: NotificationType.Error,
      message: 'notification_logout_unsuccessful'
    });
    if (error instanceof Error) {
      alert(error.message);
    }
  }
}

/**
 * Request to email reset the password
 * @param email the account to request reset for
 */
export async function requestForgotPassword(email: string) {
  try {
    const host = get(page).url.host;
    // convert 127.0.0.1 to localhost
    const hostToUse = host === '127.0.0.1:3000' ? 'localhost:3000' : host;
    const { data, error } = await get(page).data.supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${dev ? 'http' : 'https'}://${hostToUse}/auth/reset-password`
    });
    if (error) {
      notifications.createNotification(
        {
          message: error.message,
          type: NotificationType.Error
        },
        NotificationExpiration.SLOW
      );
    } else if (data) {
      notifications.createTolgeeNotification(
        {
          message: 'notification_password_reset_successful',
          type: NotificationType.Success
        },
        NotificationExpiration.SLOW
      );
    } else {
      notifications.createNotification({
        message: 'Unknown error occurred',
        type: NotificationType.Error
      });
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * Request to update the current password
 * Requires the user to be signed in
 * @param password the new password
 */
export async function updatePassword(password: string) {
  try {
    const { data, error } = await get(page).data.supabase.auth.updateUser({
      password
    });
    if (error) {
      notifications.createNotification(
        {
          message: error.message,
          type: NotificationType.Error
        },
        NotificationExpiration.SLOW
      );
    } else if (data) {
      notifications.createNotification(
        {
          message: 'Password successfully reset',
          type: NotificationType.Success
        },
        NotificationExpiration.SLOW
      );
      await goto('/');
    } else {
      notifications.createNotification({
        message: 'Unknown error occurred',
        type: NotificationType.Error
      });
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
